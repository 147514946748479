import React, { useState, useEffect, useCallback } from 'react'
import Select from 'react-select'

import { MdInsertDriveFile } from 'react-icons/md'

import { ProviderService, DocumentManagementService } from '../../../services'

import Toast from '../../../components/Toast';
import Collapse from '../../../components/Collapse'
import CalendarInput from '../../../components/CalendarInput'
import ProviderScopeSummary from '../../../components/ProviderScopeSummary'

import { dateToString, stringToDate } from '../../../helpers/forms/convert'
import { downloadDocumentFile } from '../../../utils/files'

import './styles.scss'
import Loading from '../../../components/Loading';


const DOC_CLASS = [
  { value: 'according', label: 'Conforme' },
  { value: 'not_according', label: 'Não conforme' },
  { value: 'not_applicable', label: 'Não aplicável' },
]

const Document = ({ document, scopeServiceID, isCollab, setShouldUpdateData, employeeProviderScope, setpreviousIntegrationDate }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [expiresAt, setExpiresAt] = useState(document.expiresAtDateObject)
  const [integrationDate, setIntegrationDate] = useState(new Date)
  const [status, setStatus] = useState(document.statusObject)
  const [reason, setReason] = useState(document.reason)
  const [errors, setErrors] = useState({})
  const [statusColor, setStatusColor] = useState('')

  useEffect(() => {
    if (!document.status || document.status == 'waiting') setStatusColor('field-waiting')
    else if (document.status == 'not_according') setStatusColor('field-not-according')
    else if (document.status == 'not_applicable') setStatusColor('field-not-applicable')
    else if (document.status == 'according') setStatusColor('field-according')
  }, [document.status])

  useEffect(() => {
    let dateString;
    if (employeeProviderScope?.suggestion_integration_date) {
      dateString = employeeProviderScope.suggestion_integration_date;
      setpreviousIntegrationDate(dateString);
    }
    if (employeeProviderScope?.integration_date) {
      dateString = employeeProviderScope.integration_date;
      setIntegrationDate(stringToDate(dateString));
    }
  }, [])

  const validateDocument = useCallback( async () => {
    const body = {
      document_id: document.id,
      scope_service_id: Number(scopeServiceID),
      integration_date: dateToString(integrationDate),
      status: status.value,
      reason
    }
    if (document.document_type_mandatory_expires_at || expiresAt) {
      Object.assign(body, {
        expires_at: dateToString(expiresAt)
      })
    }

    return await DocumentManagementService.postSsmaValidateDocument(body)
  }, [document, scopeServiceID, integrationDate, expiresAt, status, reason])

  const handleAnalisy = async () => {
    try {
      setIsSaving(true)
      const newErrors = {}
      Toast.warning('Enviando dados para a Sênior, aguarde')

      if (!status?.value) newErrors.status = 'Selecione a classificação do documento.'
      if (status?.value && status.value !== 'according' && !reason)
        newErrors.reason = `Informe o motivo da classificação está "${status.label}".`

      setErrors(newErrors)
      if (Object.keys(newErrors).length === 0) {
        await validateDocument()
        Toast.success('Análise concluída com sucesso!')
      }
      setIsSaving(false)
      setShouldUpdateData(true);
    } catch (error) {
      setIsSaving(false)
      if (error?.response) {
        const { response: { data, status } } = error;
        if ([400, 401, 403].includes(status)) {
          Object.keys(data.error).map(field => {
            Toast.error(...data.error[field])
          })
        } else {
          Toast.error('Não foi possível realizar a operação. Erro no servidor!')
        }
      } else {
        Toast.error('Não foi possível realizar a operação. Verifique sua conexão!')
      }
    }
  }

  const handleFileDownload = async (document) => {
    if (!document.id) return;
    Toast.warning('Iniciando download do documento')
    const { status, message } = await downloadDocumentFile(document.id, document.fileName);
    Toast.hide()
    if (status == 200) {
      Toast.success(message);
      return;
    }
    Toast.error(message);
  }

  return (
    <div className="document-container">
      <div className="form-row">
        <div className="form-field">
          <label>{document.docName}</label>
          <div className={`field field-file ${statusColor}`} onClick={(e) => handleFileDownload(document)}>
            <MdInsertDriveFile className="icon" />
            {document.fileName}
          </div>
        </div>
        <div className="form-field">
          <label>DATA DE VENCIMENTO DO DOCUMENTO{document.document_type_mandatory_expires_at ? '*' : ''}</label>
          <CalendarInput
            className="field"
            initialValue={expiresAt}
            onChange={date => setExpiresAt(date)}
            minDate={new Date()}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-field">
          <label>CLASSIFICAÇÃO DO DOCUMENTO*</label>
          <Select
            className="field field-select"
            placeholder="Classificação do documento"
            options={DOC_CLASS}
            value={status}
            onChange={docClass => setStatus(docClass)}
            isClearable
          />
          {
            errors.status && (<div className="error-field">{errors.status}</div>)
          }
        </div>
        <div className="form-field">
          <label>MOTIVO DA CLASSIFICAÇÃO{status?.value != 'according' && '*'}</label>
          <input className="field" value={reason} onChange={ev => setReason(ev.target.value)} />
          {
            errors.reason && (<div className="error-field">{errors.reason}</div>)
          }
        </div>
      </div>
      <div className="form-actions">
        <button
          className="complete-btn"
          onClick={handleAnalisy}
          disabled={isSaving}
        >
          Concluir análise
        </button>
      </div>
    </div>
  )
}

const Documentation = ({ documents, scopeServiceID, isCollab, setShouldUpdateData, employeeProviderScope }) => {
  const [integrationDate, setIntegrationDate] = useState('');
  const [previousIntegrationDate, setpreviousIntegrationDate] = useState('');

  useEffect(() => {
    let dateString;
    if (employeeProviderScope?.suggestion_integration_date) {
      dateString = employeeProviderScope.suggestion_integration_date;
      setpreviousIntegrationDate(dateString);
    }
    if (employeeProviderScope?.integration_date) {
      dateString = employeeProviderScope.integration_date;
      setIntegrationDate(stringToDate(dateString));
    }
  }, [])

  const handleIntegrationDate = async () => {
    try {
      Toast.warning(`Enviando dados para a Sênior, aguarde ...`);
      const newDateString = dateToString(integrationDate);
      const body = { 'integration_date': newDateString }
      const { data } = await DocumentManagementService.updateEmployeeIntegrationDate(employeeProviderScope.id, body);
      Toast.success(`Data de integração de ${employeeProviderScope.employee_provider.name} foi atualizada`);
      setShouldUpdateData(true);
    } catch (error) {
      const { response: { data } } = error;
      let errors = data.error || { message: [data.message] };
      Object.keys(errors).map(function (field) {
        let message = errors[field][0];
        if (message) Toast.error(message);
      });
    }
  }

  return (
    <div className="documentation-component">
      {
        isCollab && (
          <div className="document-container">
            <div className="form-row" style={{ paddingTop: '15px' }}>
              <div className="form-field">
                <label>DATA DE INTEGRAÇÃO</label>
                <CalendarInput
                  className="field"
                  initialValue={integrationDate}
                  onChange={date => setIntegrationDate(date)}
                  minDate={new Date()}
                />
              </div>
              <div className="form-field">
                <label>SUGESTÃO DE DATA DE INTEGRAÇÃO</label>
                <div className="field field-read">{previousIntegrationDate}</div>
              </div>
            </div>
            <div className="form-actions">
              <button className="complete-btn" onClick={handleIntegrationDate}>Atualizar data</button>
            </div>
          </div>
        )
      }
      {
        Array.isArray(documents) && documents.map((document) => (
          <Document
            key={`doc-${document.id}`}
            document={document}
            scopeServiceID={scopeServiceID}
            isCollab={isCollab}
            setShouldUpdateData={setShouldUpdateData}
            employeeProviderScope={employeeProviderScope}
            setpreviousIntegrationDate={setpreviousIntegrationDate}
          />
        ))
      }
    </div>
  )
}

const ValidateDocuments = ({ match }) => {
  const { id: scopeServiceID } = match.params

  const [hiredScopeData, setHiredScopeData] = useState({})
  const [employeesDocumentation, setEmployeesDocumentation] = useState([])
  const [equipmentDocumentation, setEquipmentDocumentation] = useState([])
  const [providerDocumentation, setProviderDocumentation] = useState([])
  const [shouldUpdateData, setShouldUpdateData] = useState(true);
  const [isLoadingData, setIsLoading] = useState(true);

  const getHiredScopeData = async () => {
    const { data } = await ProviderService.getHiredScopeData(scopeServiceID)
    setHiredScopeData(data)
  }

  const formatDoc = doc => {
    doc.docName = doc.document_type_name ? doc.document_type_name : doc.name
    doc.docName = doc.docName ? doc.docName.toUpperCase() : ''
    doc.fileName = doc.file?.split('/').pop() || ''
    doc.expiresAtDateObject = doc.expires_at ? stringToDate(doc.expires_at) : ''
    doc.statusObject = DOC_CLASS.find(status => status.value === doc.status) || {}
    doc.mandatory_expires_at = doc.mandatory_expires_at

    return doc
  }

  const getProviderDocumentation = async () => {
    try {
      let { data } = await DocumentManagementService.getProviderDocumentationsToAnalysis(scopeServiceID);
      data = Array.isArray(data) && data.map(doc => formatDoc(doc)) || []
      setProviderDocumentation([data])
    } catch (error) {
      setProviderDocumentation([]);
      const { response: { data } } = error;
      let errors = data.error || { message: [data.message] } || {};
      Object.keys(errors).map(function (field) {
        let message = errors[field][0];
        if (message) Toast.error(message);
      });
    }
  }

  const getEquipmentDocumentation = async () => {
    try {
      let { data } = await DocumentManagementService.getProviderEquipmentsDocumentationsToAnalysis(scopeServiceID);
      data = Array.isArray(data) && data.map(doc => formatDoc(doc)) || []
      setEquipmentDocumentation([data])
    } catch (error) {
      setEquipmentDocumentation([]);
      const { response: { data } } = error;
      let errors = data.error || { message: [data.message] } || {};
      Object.keys(errors).map(function (field) {
        let message = errors[field][0];
        if (message) Toast.error(message);
      });
    }
  }

  const getEmployeesDocumentation = async () => {
    try {
      let { data } = await DocumentManagementService.getProviderEmployeesDocumentationsSSMA(scopeServiceID)
      data = Array.isArray(data) && data.map(empDoc => {
        empDoc.employee_provider.documents = empDoc?.employee_provider.documents?.map(doc => formatDoc(doc))
        return empDoc
      }) || []
      setEmployeesDocumentation(data)
    } catch (error) {
      setEmployeesDocumentation([]);
      const { response: { data } } = error;
      let errors = data.error || { message: [data.message] } || {};
      Object.keys(errors).map(function (field) {
        let message = errors[field][0];
        if (message) Toast.error(message);
      });
    }
  }

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    if (shouldUpdateData) {
      const getAllData = async () => {
        await Promise.allSettled([
          getHiredScopeData(),
          getProviderDocumentation(),
          getEquipmentDocumentation(),
          getEmployeesDocumentation(),
        ]);

        setIsLoading(false)
      }
        
      getAllData();
      setShouldUpdateData(false);
    }
  }, [shouldUpdateData])

  if (isLoadingData)
    return (
      <section className="validate-documents-page">
        <div className="load-container">
          <Loading />
        </div>
      </section>
    );

  return (
    <section className="validate-documents-page">
      <div className="page-container">
        <div className="page-header">
          <h3>ANÁLISE DE DOCUMENTAÇÃO</h3>
        </div>
        <div className="page-content">
          <ProviderScopeSummary data={hiredScopeData} />
          <div className="docs-content">
            {
              Array.isArray(providerDocumentation) && providerDocumentation.map(providerDoc => (
                <Collapse
                  key={`provider-doc-${providerDoc.id}`}
                  title="Documentos da empresa"
                >
                  <Documentation
                    documents={providerDoc || []}
                    scopeServiceID={scopeServiceID}
                    setShouldUpdateData={setShouldUpdateData}
                  />
                </Collapse>
              ))
            }
            {
              Array.isArray(equipmentDocumentation) && equipmentDocumentation.map(equipDoc => (
                <Collapse
                  key={`equip-doc-${equipDoc.id}`}
                  title="Documentos de equipamento"
                >
                  <Documentation
                    documents={equipDoc || []}
                    scopeServiceID={scopeServiceID}
                    setShouldUpdateData={setShouldUpdateData}
                  />
                </Collapse>
              ))
            }
            {
              Array.isArray(employeesDocumentation) && employeesDocumentation.map(employeeProviderScope => (
                <Collapse
                  key={`employee-doc-${employeeProviderScope.id}`}
                  title={`${employeeProviderScope?.employee_provider.name} | Documentos`}
                >
                  <Documentation
                    documents={employeeProviderScope?.employee_provider.documents || []}
                    scopeServiceID={scopeServiceID}
                    setShouldUpdateData={setShouldUpdateData}
                    employeeProviderScope={employeeProviderScope}
                    isCollab
                  />
                </Collapse>
              ))
            }
          </div>
        </div>
      </div>
    </section>
  );
}

export default ValidateDocuments
